import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './context/AuthContext';

function App({ children }) {
  return (
    <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        {children}
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default App;
