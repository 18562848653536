import React from 'react';
import './Section.scss';
import { HelpOutline } from '@mui/icons-material';

export default function Section({ title, desc, moreInfo, children }) {
  return (
    <div className='section'>
        <div className='section-header'>
            <header>
                <div>
                    {title && <h2>{title}</h2>}
                    {desc && 
                        <div>
                            <div>
                                {`${desc} `}
                                {moreInfo &&
                                    <a className='btn text-lite' href={moreInfo} target='_blank' rel='noreferrer noopener'>Más información <HelpOutline /></a>
                                }
                            </div>
                            
                        </div>
                    }
                </div>
            </header>
        </div>
        {children}
    </div>
  )
}
