import { AddAPhotoOutlined } from '@mui/icons-material'
import { Avatar, IconButton } from '@mui/material'
import React from 'react'

export default function AvatarButton({ 
    src, 
    onClick,
    variant,
    fontSize
}) {
    return (
        <IconButton 
            className={`btn avatar variant-${variant}`}
            onClick={onClick}
            style={{
                fontSize: fontSize
            }}
        >
            <Avatar src={src} />
            <div className='banner'>
                <AddAPhotoOutlined />
            </div>
        </IconButton>
    )
}
