import React, { useContext, useEffect, useState } from 'react';
import './Header.scss';
import { Avatar, Button, CircularProgress, Collapse, Fade, IconButton, LinearProgress, Menu, Tab, Tabs, Tooltip } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { AddCircle, Close, ExpandLess, Logout, Refresh } from '@mui/icons-material';
import Cookies from "js-cookie";
import decrypt from '../functions/decrypt';
import { doc, getDoc } from 'firebase/firestore';
import { auth, sessionsFirestore, usersFirestore } from '../services/firebase/firebase';
import { getTimeStamp } from '../functions';

function useRouteMatch(patterns) {
    const { pathname } = useLocation();

    for (let i = 0; i < patterns.length; i += 1) {
        const pattern = patterns[i];
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

const TabsComponent = () => {
    const routeMatch = useRouteMatch(['/', '/personal-info', '/data-and-privacy', '/security'])
    const currentTab = routeMatch?.pattern?.path;
    
    return (
        <Tabs 
            value={currentTab}  
            className='tabs' 
            variant="scrollable"
            scrollButtons
        >
            <Tab className='tab-link' component={Link} value="/" to="/" label="Página principal" />
            <Tab className='tab-link' component={Link} value="/personal-info" to="/personal-info" label="Información personal" />
            <Tab className='tab-link' component={Link} value="/data-and-privacy" to="/data-and-privacy" label="Datos y privacidad" />
            <Tab className='tab-link' component={Link} value="/security" to="/security" label="Seguridad" />
        </Tabs>
    )
}

const HeaderMenu = ({ handleClose, openSubMenu, setOpenSubMenu, user, login, logout }) => {
    const [ loading, setLoading ] = useState(true);
    const [ submitting, setSubmitting ] = useState(false);
    const [ accounts, setAccounts ] = useState([]);

    useEffect(() => {
        async function getUserDocuments(uids) {
            const userDocs = await Promise.all(uids.map(async (uid) => {
                const decryptUid = await decrypt(uid);

                const userDocRef = doc(usersFirestore, `${decryptUid}/public`);
                const userDoc = await getDoc(userDocRef);

                if (userDoc.exists()) {
                    return { id: decryptUid, ...userDoc.data().data[0] };
                } else {
                    console.log(`No such document for user ID: ${decryptUid}`)
                    return null;
                }
            }))

            return userDocs.filter(userDoc => userDoc !== null);
        }

        async function getAccounts() {
            const session = Cookies.get('sessionId');

            if (session) {

                if (sessionStorage.getItem('session_accounts')) {
                    const data = JSON.parse(sessionStorage.getItem('session_accounts'));

                    setAccounts(data);
                    setLoading(false);
                } else {
                    const sessionId = await decrypt(session);
                    const listRef = doc(sessionsFirestore, `active_sessions/${sessionId}/accounts/list`);
                    const listSnap = await getDoc(listRef);
    
                    if (listSnap.exists()) {
                        const docItems = listSnap.data();
                        const data = docItems.data;
    
                        getUserDocuments(data)
                            .then(userDocs => {
                                setAccounts(userDocs);
                                sessionStorage.setItem('session_accounts', JSON.stringify(userDocs))
                                setLoading(false);
                            })
                            .catch(error => {
                                console.error('Error fetching user documents: ', error)
                            })
                    } else {
                        setAccounts([]);
                        setLoading(false);
                    }
                }
            } else {
                setAccounts([]);
                setLoading(false);
            }
        }

        if (loading) {
            getAccounts();
        }
    }, [loading])

    return (
        <>
            {submitting && <LinearProgress className='progress linear' />}
            <div className='account-menu-top'>
                <div>{user?.email}</div>
                <IconButton onClick={handleClose}>
                    <Close />
                </IconButton>
            </div>
            <div style={{ maxHeight: '70vh', overflowY: 'auto'}}>
                <div className='account-menu-user'>
                    <Avatar 
                        sx={{
                            width: 75,
                            height: 75,
                        }}
                        src={`${user?.photoURL}&t=${getTimeStamp()}`}
                    />
                    <div className='greetings'>{`¡Hola, ${user?.displayName ? user.displayName : 'Sin nombre'}!`}</div>
                </div>
                {loading ?
                    <div className='flex justify-center' style={{ padding: '1rem'}}><CircularProgress className='progress circular' /></div>
                :
                    <>
                        {accounts.length > 0 &&
                            <div className={`account-menu-menu ${openSubMenu ? 'open' : 'close'}`}>
                                <Button className='btn expander' onClick={() => setOpenSubMenu(!openSubMenu)}>
                                    <span>
                                        {openSubMenu ? 'Ocultar más cuentas' : 'Mostrar más cuentas'}
                                    </span>
                                    <div className={`icon`}>
                                        <ExpandLess />
                                    </div>
                                </Button>
                                <Collapse in={openSubMenu} timeout="auto">
                                    <div className='collapsable'>
                                        {accounts.map((account) => (
                                            <Button 
                                                onClick={() => {
                                                    setSubmitting(true);
                                                    if (auth.currentUser.uid === account.id) {
                                                        handleClose();
                                                    } else {
                                                        login(account.id);
                                                    }
                                                }} 
                                                className='btn account-chooser' 
                                                key={account.email}
                                            >
                                                <div className='layout'>
                                                    <div>
                                                        <div className='avatar'>
                                                            <Avatar 
                                                                alt={account.name}
                                                                src={`${account.photoURL}&t=${getTimeStamp()}`}
                                                            />
                                                        </div>
                                                        <div className='body'>
                                                            <div className='name'>{account.name}</div>
                                                            <div className='email'>{account.email}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Button>
                                        ))}
                                        <Button 
                                            onClick={() => {
                                                sessionStorage.removeItem('session_accounts');
                                                setLoading(true)
                                            }}
                                            className='btn account-chooser'
                                        >
                                            <div className='layout'>
                                                <div>
                                                    <div className='icon'>
                                                        <Refresh />
                                                    </div>
                                                    <div className='body'>
                                                        <div className='name'>Actualizar lista</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                </Collapse>
                            </div>
                        }
                    </>
                }

               
                
                <div className='account-menu-menu-two'>
                    <Button 
                        onClick={logout}
                        to="https://accounts.yugacore.com/v1/signin?origin=Y%C3%BCga&continue=https%3A%2F%2Faccounts.yugacore.com" 
                        className='btn high'
                    >
                        <Logout />
                        <span className='title'>Cerrar sesión</span>
                    </Button>

                    <Button 
                        component={Link} 
                        to="https://accounts.yugacore.com/v1/signin?origin=Y%C3%BCga&continue=https%3A%2F%2Faccounts.yugacore.com" 
                        className='btn high'
                    >
                        <AddCircle />
                        <span className='title'>Añadir otra cuenta</span>
                    </Button>
                </div>
                <div className='flex justify-between' style={{ padding: '1rem'}}>
                    <Button className='btn text-lite'>
                        <span>Políticas de Privacidad</span>
                    </Button>
                    <Button className='btn text-lite'>
                        <span>Condiciones del Servicio</span>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default function Header() {
    const { account, login, logout } = useContext(AuthContext);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ openSubMenu, setOpenSubMenu ] = useState(true);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        if (account === undefined || account === null) {
            handleClose();
        }
    }, [account])

    return (
        <div className='header'>
            <div className='superior'>
                <div className='logo'>
                    <span className='brand'>Yüga</span>
                    <span className='app'>Accounts</span>
                </div>
                <div>
                    <Tooltip title="Cuenta de Yüga">
                        <IconButton
                            onClick={handleClick}
                        >
                            <Avatar 
                                className='dark'
                                src={`${account?.photoURL}&t=${getTimeStamp()}`}
                            />
                        </IconButton>
                    </Tooltip>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        className='account-menu'
                        TransitionComponent={Fade}
                    >
                        <HeaderMenu 
                            handleClose={handleClose} 
                            openSubMenu={openSubMenu}
                            setOpenSubMenu={setOpenSubMenu}
                            user={account}
                            login={login}
                            logout={logout}
                        />
                    </Menu>
                </div>
            </div>
            <div>
                <TabsComponent />
            </div>
        </div>
    )
}
