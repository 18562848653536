import { Avatar, Button } from '@mui/material'
import React, { useRef, useState } from 'react'
import Modal from '../../../../components/modal/Modal'
import PfpEditor from './PfpEditor';
import AvatarButton from '../../../../components/buttons/AvatarButton';
import { Delete, Edit } from '@mui/icons-material';
import { getTimeStamp } from '../../../../functions';

export default function PfpButton({ 
    title, 
    value,
    onClick,
    setPrincipalLoading
}) {
    const pfpInputRef = useRef(null);
    const [ open, setOpen ] = useState(false);
    const [ openEditor, setOpenEditor ] = useState(false);

    const [ file, setFile ] = useState(null);
    
    const [ loading, setLoading ] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleEditorOpen = () => {
        setOpenEditor(true);
    }

    const handleEditorClose = () => {
        setOpenEditor(false);
    }

    const handleBack = () => {
        handleOpen();
        setTimeout(() => {
            handleEditorClose();
        }, 50);
    }

    const handleImageClick = () => {
        setLoading(true);
        pfpInputRef.current.click();

        setTimeout(() => {
            if (pfpInputRef.current.value === "") {
                setLoading(false);
            }
        }, 2000)
    }

    const handleImageChange = (e) => {
        const files = e.target.files;

        if (files.length > 0) {
            setFile(URL.createObjectURL(e.target.files[0]));
            handleEditorOpen();
            setTimeout(() => {
                setLoading(false);
                handleClose();
                pfpInputRef.current.value = '';
            }, 50);
        } else {
            setLoading(false);
            pfpInputRef.current.value = '';
        }
    }

    return (
        <>
            <Button className='btn section' onClick={handleOpen}>
                <div className="body">
                    <div className="content">
                        <div style={{ margin: 'auto'}}>Foto de perfil</div>
                    </div>
                    <div className="icon">
                        <Avatar 
                            sx={{
                                width: '3rem',
                                height: '3rem'
                            }}
                            src={`${value}?t=${getTimeStamp()}`}
                        />
                    </div>
                </div>
            </Button>
            <Modal
                open={open}
                loading={loading}
                title="Cambiar Foto de perfil"
                close={handleClose}
                maxWidth={400}
            >
                <div>
                    <div className='font p-small'>
                        Una foto ayudará a las personas a reconocerte y te permitirá saber cuando hayas accedido a la cuenta
                    </div>
                    <div style={{ marginTop: '1rem'}} className='flex justify-center align-center'>
                        <AvatarButton 
                            onClick={handleImageClick}
                            src={`${value}?t=${getTimeStamp()}`}
                            fontSize='10rem'
                        />
                    </div>
                </div>
                <div className='flex flow-row justify-between' style={{ marginTop: '2rem', gap: '1rem'}}>
                    <Button className='btn modal-high' onClick={handleImageClick}>
                        <Edit />
                        Cambiar
                    </Button>
                    <Button className='btn modal-high'>
                        <Delete />
                        Eliminar
                    </Button>
                </div>
                <input 
                    type='file'
                    accept='image/*'
                    id="input-avatar"
                    className='hide'
                    onChange={handleImageChange}
                    ref={pfpInputRef}
                />
            </Modal>
            <Modal
                open={openEditor}
                loading={loading}
                maxWidth={500}
                close={handleEditorClose}
                title="Recortar y ajustar"
            >
                <PfpEditor 
                    originalImage={value}
                    image={file} 
                    close={handleEditorClose}
                    back={handleBack} 
                    setLoading={setLoading} 
                    setPrincipalLoading={setPrincipalLoading}
                />
            </Modal>
        </>
    )
}
