import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD0fVEMvwDKplo_P9F8euoBytptMXIoU3U",
  authDomain: "yuga-core.firebaseapp.com",
  projectId: "yuga-core",
  storageBucket: "yuga-core.appspot.com",
  messagingSenderId: "237396403087",
  appId: "1:237396403087:web:3573c3259e372e4d49e1fa",
  measurementId: "G-XNPRLRJY7C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const usersFirestore = getFirestore(app, 'users');
export const sessionsFirestore = getFirestore(app, 'sessions');
export const usersStorage = getStorage(app, 'gs://users-yuga');
export const functions = getFunctions(app);