import { AddAPhotoOutlined, Delete, Edit } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { useRef, useState } from 'react'
import Modal from '../../../../components/modal/Modal';
import PfbEditor from './PfbEditor';
import { getTimeStamp } from '../../../../functions';

export default function PfbButton({ 
    title, 
    value,
    onClick,
    setPrincipalLoading
}) {
    const pfpInputRef = useRef(null);
    const [ open, setOpen ] = useState(false);
    const [ openEditor, setOpenEditor ] = useState(false);

    const [ file, setFile ] = useState(null);
    
    const [ loading, setLoading ] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleEditorOpen = () => {
        setOpenEditor(true);
    }

    const handleEditorClose = () => {
        setOpenEditor(false);
    }

    const handleBack = () => {
        handleOpen();
        setTimeout(() => {
            handleEditorClose();
        }, 50);
    }

    const handleImageClick = () => {
        setLoading(true);
        pfpInputRef.current.click();

        setTimeout(() => {
            if (pfpInputRef.current.value === "") {
                setLoading(false);
            }
        }, 2000)
    }

    const handleImageChange = (e) => {
        const files = e.target.files;

        if (files.length > 0) {
            setFile(URL.createObjectURL(e.target.files[0]));
            handleEditorOpen();
            setTimeout(() => {
                setLoading(false);
                handleClose();
                pfpInputRef.current.value = '';
            }, 50);
        } else {
            setLoading(false);
            pfpInputRef.current.value = '';
        }
    }

    return (
        <>
            <Button className='btn section' onClick={handleOpen}>
                <div className="body">
                    <div className="content">
                        <div style={{ margin: 'auto'}}>Fondo del perfil</div>
                        <span>Algunos sitios/app's tienen fondos para el perfil de los usuarios, te aligeramos un poco el trabajo ;)</span>
                    </div>
                </div>
                <div className="banner">
                    <img src={`${value}?t=${getTimeStamp()}`} alt="" />
                    <div className="svg-container" >
                        <AddAPhotoOutlined />
                    </div>
                </div>
            </Button>
            <Modal
                open={open}
                loading={loading}
                maxWidth={650}
                title="Cambiar Fondo del perfil"
                close={handleClose}
            >
                <div>
                    <div className="font p-small">
                        Con el fondo crearas un ambiente visual que represente lo que eres
                    </div>
                    <div style={{ marginTop: '1rem'}}>
                        <Button className='btn section no-text' onClick={handleImageClick}>
                            <div className="banner">
                                <img src={`${value}?t=${getTimeStamp()}`} alt="" />
                                <div className="svg-container">
                                    <AddAPhotoOutlined />
                                </div>
                            </div>
                        </Button>
                    </div>
                </div>
                <div className='flex flow-row justify-between' style={{ marginTop: '2rem', gap: '1rem'}}>
                    <Button className='btn modal-high' onClick={handleImageClick}>
                        <Edit />
                        Cambiar
                    </Button>
                    <Button className='btn modal-high'>
                        <Delete />
                        Eliminar
                    </Button>
                </div>
                <input 
                    type='file'
                    accept='image/*'
                    id="input-avatar"
                    className='hide'
                    onChange={handleImageChange}
                    ref={pfpInputRef}
                />
            </Modal>
            <Modal
                open={openEditor}
                loading={loading}
                maxWidth={650}
                close={handleEditorClose}
                title="Recortar y ajustar"
            >
                <PfbEditor 
                    originalImage={value}
                    image={file} 
                    close={handleEditorClose}
                    back={handleBack} 
                    setLoading={setLoading} 
                    setPrincipalLoading={setPrincipalLoading}
                />
            </Modal>
        </>
    )
}
