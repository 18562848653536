import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Avatar, Button } from '@mui/material';
import './Home.scss';
import { getTimeStamp } from '../functions';
import Loading from '../components/Loading';

export default function Home() {
  const { account } = useContext(AuthContext);

  if (!account) return <Loading />

  return (
    <div className='home'>
      <div>
        <header className='home-banner'>
          <div className='user-photo'>
            <div className='user-photo_layout'>
              <Button
                className='btn'
              >
                <Avatar 
                  className='avatar'
                  src={`${account.photoURL}?t=${getTimeStamp()}`}
                  alt={account.displayName}
                />
              </Button>
            </div>
          </div>
          <h1>Bienvenido, {account.displayName}</h1>
        </header>
        {/* <Link to="/personal-info">Información personal</Link>
        <div>
          <img src={account.photoURL} />
        </div>
        <div>
          <span>{account.displayName}</span>
          <span>{account.email}</span>
        </div> */}
      </div>
    </div>
  )
}
