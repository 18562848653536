import { CircularProgress } from '@mui/material'
import React from 'react'

export default function Loading() {
  return (
    <div 
      className='full-width'
      style={{
          padding: '2rem 1rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1
      }}
    >
        <CircularProgress className='progress circular' />
    </div>
  )
}
