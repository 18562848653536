import { ArrowForwardIos, TimerOff } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'

export default function SectionButton({ 
    title, 
    value,
    onClick,
    icon,
    expired
}) {
    const handleOnClick = () => {
        onClick()
    }

    return (
        <Button className='btn section session' onClick={handleOnClick}>
            <div className={`body expired-${expired}`}>
                <div className="content">
                    <h3>
                        {title ? title : expired ? 'La sesión expiró' : 'Sesión activa'}
                    </h3>
                    <div>{value}</div>
                </div>
                <div className="icon">
                    {icon ?
                        <>
                            {expired ? 
                            <TimerOff style={{ fontSize: '1.5em'}} />
                            :
                            <>{icon}</>
                            }
                        </>
                        :
                        <ArrowForwardIos />
                    }
                </div>
            </div>
        </Button>
    )
}
