import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { auth, usersFirestore } from '../../../services/firebase/firebase';
import { Avatar, Button, Skeleton } from '@mui/material';

export default function Connections() {
    const [ connections, setConnections ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        async function getConnections() {
            const storage = sessionStorage.getItem('connections');

            if (storage) {
                setConnections(JSON.parse(storage));
                setLoading(false);
            } else {
                const connectionsRef = doc(usersFirestore, `${auth.currentUser.uid}/data-and-privacy`);
                const connectionsSnap = await getDoc(connectionsRef);
    
                if (connectionsSnap.exists()) {
                    const data = connectionsSnap.data().connections;
    
                    if (data) {
                        setConnections(data);
                    } else {
                        setConnections([])
                    }
    
                    setLoading(false)
                } else {
                    setConnections([]);
                    setLoading(false)
                }
            }
        }

        getConnections();
    }, [])

    return (
        <>
            {loading ?
                <>
                    {Array.from({ length: 3}).map((arr) => (
                        <div className="btn connection-app" key={arr}>
                            <div className="connection-app-content">
                                <div className="logo">
                                    <Skeleton variant='rectangular' height={'100%'} />
                                </div>
                                <div className="body">
                                    <div className="name">
                                        <Skeleton variant='text' width={'30%'} height={'100%'} />
                                    </div>
                                    <div className="desc">
                                        <Skeleton variant='text' width={'70%'} height={'100%'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </>
                :
                <>
                    {connections.map((connection) => (
                        <div className='btn connection-app' key={connection.name}>
                            <Button className='connection-app-content'>
                                <div className='logo'>
                                    <Avatar 
                                        src={connection.logo}
                                        sx={{
                                            borderRadius: 0,
                                            width: '100%',
                                            height: '100%'                            
                                        }}
                                    />
                                </div>
                                <div className='body'>
                                    <div className='name'>{connection.name}</div>
                                    <div className='desc'>{connection.desc}</div>
                                </div>
                            </Button>
                        </div>
                    ))}
                </>
            }
        </>
    )
}
