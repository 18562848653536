import React from 'react'
import SectionButton from '../../../../components/buttons/SectionButton'

export default function PrimaryEmailButton({ data }) {
  return (
    <SectionButton 
        title="Correo electrónico principal"
        value={data}
    />
  )
}
