import React from 'react'
import PrimaryEmailButton from './primary-email/Button'

export default function ContactInfo({ originalArray, data }) {
    return (
        <>
            <PrimaryEmailButton data={data.primaryEmail} />
        </>
    )
}
