import { createContext, useEffect, useState } from "react";
import { auth } from "../services/firebase/firebase";
import { onAuthStateChanged, signInWithCustomToken, signOut } from "firebase/auth";
import Cookies from 'js-cookie';
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ account, setAccount ] = useState(undefined);
    const [ recentLogin, setRecentLogin ] = useState(false);

    const handleLogin = async (uid) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URI}/verifyUid`, {
                uid: uid
            })

            setAccount(undefined);

            const customToken = response.data.customToken;

            const user = await signInWithCustomToken(auth, customToken);
            const idToken = await user.user.getIdToken();
            Cookies.set('authToken', idToken, { domain: '.yugacore.com' });

            sessionStorage.removeItem('personal-info');
            sessionStorage.removeItem('sessions');
        } catch (error) {
            console.log(error);
        }
    }

    const applyAuthState = async () => {
        const idToken = Cookies.get('authToken');
        console.log(3);
        console.log(idToken)
        if (idToken) {
            console.log(4);
            try {
                console.log(5);
                setAccount(null);
                const response = await axios.post('https://us-central1-yuga-core.cloudfunctions.net/api/verifyToken', { idToken });
                const customToken = response.data.customToken;
                const userCredential = await signInWithCustomToken(auth, customToken);

                localStorage.setItem('authToken', idToken);

                console.log('usuario autenticado', userCredential.user);
                setAccount(userCredential.user);
            } catch (error) {
                console.log(6);
                console.log('error al autenticar con el token:', error);
                setAccount(auth.currentUser);
            }
        } else {
            console.log(7);
            setAccount(null);
        }
    }

    function restoreLocalStorage() {
        console.log('Datos borrados')
        localStorage.removeItem('personal-info');
        localStorage.removeItem('sessions');
        console.log('Datos borrados')
        sessionStorage.removeItem('personal-info');
        sessionStorage.removeItem('sessions');
    }

    useEffect(() => {
        const loginWithSession = async (user) => {
            try {
                const customTokenResponse = await fetch(`https://api.yugacore.com/auth/custom-token`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid: user.uid }),
                    credentials: "include",
                });

                if (!customTokenResponse.ok) {
                    console.error('Error al obtener el custom token:', customTokenResponse.statusText);
                    return;
                }

                const { customToken } = await customTokenResponse.json();

                if (customToken) {
                    setRecentLogin(true);
                    const user = await signInWithCustomToken(auth, customToken);
                    restoreLocalStorage();
                    setAccount(user.user);
                    console.log('Sesión iniciada exitosamente con Firebase.');
                } else {
                    console.error('No se obtuvo un custom token válido.')
                }
            } catch (error) {
                console.error('Error al iniciar sesión', error)
            }
        }

        const renewSession = async (firebaseUser, cookieData) => {
            try {
                const token = await firebaseUser.getIdToken();

                console.log(token)

                const response = await fetch(`https://api.yugacore.com/auth/renew-session`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ token }),
                    credentials: "include",
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log(data)

                    let newObject = cookieData;

                    newObject.IOAwTMhRxZGf = false;

                    Cookies.set('session_alive', JSON.stringify(newObject), {
                        expires: 14,
                        domain: '.yugacore.com',
                    });

                    setAccount(firebaseUser);
                    return;
                } else {
                    console.error('No se renovó la sesión.');
                }
            } catch (error) {
                console.error('Error renovando la sesión.', error)
            }
        }

        const verifyCookie = async (firebaseUser) => {
            const sessionLifetime = Cookies.get('session_lifetime');
            const sessionAlive = Cookies.get('session_alive');

            if (!sessionLifetime || !sessionAlive) {
                console.log('Faltan cookies importantes, redirigiendo...');
                redirectToLogin();
                return;
            }

            console.log('Ambas cookies detectadas.');

            const now = Date.now();
            const isExpired = sessionLifetime < now - (2 * 24 * 60 * 60 * 1000);

            if (isExpired) {
                console.log('Cookie session_lifetime expirada, redirigiendo...');
                redirectToLogin();
                return;
            }

            const cookieData = JSON.parse(sessionAlive);

            if (cookieData.IOAwTMhRxZGf) {
                console.log('Cookie session_alive indica que es necesaria una verificación adicional.');
                await verifySession(firebaseUser);
            } else {
                console.log('Cookie session_alive válida, renovando sesión.');
                await renewSession(firebaseUser, cookieData);
                setAccount(firebaseUser);
            }
        };

        const redirectToLogin = () => {
            window.location.href = `https://accounts.yugacore.com/v1/signin?origin=zWRPsx1OqWLfBmlKYJLG&continue=${encodeURIComponent(window.location.origin)}`;
        };

        const verifySession = async (firebaseUser) => {
            try {
                const response = await fetch(`https://api.yugacore.com/auth/verify-session`, {
                    method: 'POST',
                    credentials: "include"
                });

                if (response.ok) {
                    const { user } = await response.json();

                    if (!firebaseUser) {
                        console.log('No existe el usuario');
                        loginWithSession(user);
                        return;
                    }

                    if (firebaseUser.uid === user.uid) {
                        setAccount(firebaseUser);
                    } else {
                        loginWithSession(user);
                    }
                    
                } else {
                    redirectToLogin();
                    console.error('Sesión no válida, usuario no autenticado.');
                    setAccount(null);
                }
            } catch (error) {
                console.error('ERror al verificar sesión:', error);
                setAccount(null);
            }
        }

        onAuthStateChanged(auth, (firebaseUser) => {
            const start = performance.now();
            console.log('onAuthStateChanged llamado', start);

            const sessionLifetime = Cookies.get('session_lifetime');
            const sessionAlive = Cookies.get('session_alive');

            if (recentLogin) {
                console.log('recentLogin');
                setAccount(firebaseUser);
                console.log('Tiempo de ejecución:', performance.now() - start);
                return;
            }

            if (!firebaseUser) {
                if (sessionLifetime && sessionAlive) {
                    console.log('Sin firebaseUser pero cookies válidas, verificando sesión...');
                    verifyCookie(null);
                    return;
                } else {
                    console.log('sin firebaseUser y sin cookies de sesión, redirigiendo..');
                    redirectToLogin();
                }
                return;
            }

            console.log('Firebase user dectetado.')
            verifyCookie(firebaseUser);
        
            console.log('Tiempo de ejecución:', performance.now() - start);
        });
    }, [])

    /* useEffect(() => {
        const idToken = Cookies.get('authToken');
        const localToken = localStorage.getItem('authToken');
        console.log(0);
        
        onAuthStateChanged(auth, (user) => {
            sessionStorage.removeItem('personal-info');
            sessionStorage.removeItem('sessions');
            if (user) {
                if (((!localToken) || (idToken !== localToken)) && idToken) {
                    console.log(1);
                    applyAuthState();
                } else {
                    setAccount(user);
                }
            } else {
                const getUserProfile = async () => {
                    try {
                        const response = await fetch(`${process.env.REACT_APP_API_URI}/profile`, {
                            method: 'GET',
                            credentials: "include",
                        });

                        if (response.ok) {
                            const data = await response.json();
                            console.log('Usuario autenticado:', data.user);
                        } else {
                            console.error('Sesión no valida.');
                        }
                    } catch (error) {
                        console.error('Error al verificar sesión:', error)
                    }
                }
                getUserProfile();
                setAccount(null);
            }
        });
    }, []); */

    const handleLogout = async () => {
        try {
            Cookies.remove('session_alive', {
                domain: '.yugacore.com',
            });
            Cookies.remove('session_lifetime', {
                domain: '.yugacore.com',
            });
            await signOut(auth);
        } catch (error){
            console.error(error);
        }
    }

    console.log(account)

    const value = {
        account,
        login: handleLogin,
        logout: handleLogout,
    };
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}