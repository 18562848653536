import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import { AuthContext } from '../context/AuthContext';
import Loading from '../components/Loading';
import { LinearProgress } from '@mui/material';

export default function Layout() {
  const { account } = useContext(AuthContext);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    if (account === undefined) {
      setLoading(true);
    } else if (account === null) {
      console.log('nuloo')
      // window.location.href = 'https://accounts.yugacore.com/v1/signin?origin=Y%C3%BCga&continue=https%3A%2F%2Faccounts.yugacore.com'
    } else {
      setLoading(false)
    }
  }, [account])

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 100,
          transition: '.2s ease all',
          opacity: loading ? 1 : 0,
          visibility: loading ? 'visible' : 'hidden'
        }}
      >
        <div
          style={{
            background: '#FFF',
            width: '100%',
            height: '100%',
            opacity: .3
          }}
        >
        </div>
        <LinearProgress value={loading ? 0 : 'progress'} className='progress linear high-contrast' />
      </div>
      <Header />
      {loading ? <Loading /> : <Outlet />}
    </>
  )
}
