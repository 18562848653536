import { Button } from '@mui/material'
import React from 'react'

export default function CardButton({ onClick, title, icon, variant, noSeparator }) {

  const handleClick = () => {
    onClick();
  }

  return (
    <div className={`btn card ${variant} ${noSeparator && 'no-separator'}`}>
      <Button className='card-link' onClick={handleClick}>
        <div className='card-link_layout'>
          <div>
            <div className="icon">
              {icon}
            </div>
            <div className="body">
              <div className="name">{title}</div>
            </div>
          </div>
        </div>
      </Button>
    </div>
  )
}
