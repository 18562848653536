import React, { useState } from 'react'
import SectionButton from '../../../../components/buttons/SectionButton'
import Modal from '../../../../components/modal/Modal'
import { Button, Divider, TextField } from '@mui/material'
import { auth, usersFirestore } from '../../../../services/firebase/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';

export default function NameButton({ data, originalArray, setPrincipalLoading }) {
  const [ name, setName ] = useState(data.name ? data.name : '');
  const [ lastName, setLastName ] = useState(data.lastName ? data.lastName : '');
  const [ publicName, setPublicName ] = useState(data.publicName ? data.publicName : '');

  const [ open, setOpen ] = useState(false);

  const [ loading, setLoading ] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);

    async function updateData() {
      try {
        let nameChanged = false;
        let lastNameChanged = false;
        let publicNameChanged = '';
  
        if (name !== data.name) nameChanged = true;
    
        if (lastName !== data.lastName) lastNameChanged = true;
  
        if (publicName && publicName !== data.publicName) {
          publicNameChanged = publicName;
        } else if (!publicName && nameChanged) {
          publicNameChanged = name;
        }

        if (!nameChanged && !lastNameChanged && !publicNameChanged) {
          handleClose();
          setLoading(false);
          return;
        }
  
        if (publicNameChanged !== '') {
          const publicUserRef = doc(usersFirestore, `${auth.currentUser.uid}/public`)
        
          await updateDoc(publicUserRef, {
            data: [{
              email: originalArray.contact.primaryEmail,
              name: publicNameChanged,
              photoURL: originalArray.basic.photoURL
            }]
          })
          
          await updateProfile(auth.currentUser, {
            displayName: publicNameChanged
          })
        }
  
        if (nameChanged || lastNameChanged) {
          const userRef = doc(usersFirestore, `${auth.currentUser.uid}/personal-info`);
  
          const personalArray = originalArray;
  
          personalArray.basic.name = nameChanged ? name : data.name;
          personalArray.basic.lastName = lastNameChanged ? lastName : data.lastName;
          personalArray.basic.publicName = publicNameChanged ? publicNameChanged : data.publicName;
  
          await updateDoc(userRef, {
            data: [personalArray]
          })
        }
  
        sessionStorage.removeItem('personal-info');      
        setPrincipalLoading(true);
        setLoading(false);
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    updateData();
  }

  return (
    <>
      <SectionButton 
        title="Nombre"
        value={data.name ? data.name : 'Sin nombre'}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        title={"Cambiar nombre"}
        loading={loading}
        close={handleClose}
      >
        <form onSubmit={handleUpload}>
          <div className='flex flow-column' style={{ gap: '1rem'}}>
            <h3>Información personal</h3>
            <TextField 
              label="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField 
              label="Apellido (Opcional)"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <Divider />
            <h3>Nombre público</h3>
            <span className='font p-small'>Esté es el nombre que todos podrán ver, por defecto será tu nombre.</span>
            <TextField 
              label={publicName.length < 1 ? name.length < 1 ? '¿Cómo quieres que te reconozcan?' : name : '¿Cómo quieres que te reconozcan?'}
              value={publicName}
              placeholder={publicName.length < 1 ? name.length < 1 ? '¿Cómo quieres que te reconozcan?' : name : '¿Cómo quieres que te reconozcan?'}
              onChange={(e) => setPublicName(e.target.value)}
            />
          </div>
          <div className='flex flex-row justify-between' style={{ gap: '2rem', margin: '2rem 0 .5rem'}}>
            <Button className='btn modal-high' type='button' onClick={handleClose}>
              Cancelar
            </Button>

            <Button className='btn high' type='submit' style={{ flex: 1}}>
              Guardar
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
