import React from 'react'
import NameButton from './name/Button'
import BirthdateButton from './birthdate/Button'
import GenderButton from './gender/Button'

export default function BasicInfo({ originalArray, data, setPrincipalLoading }) {
    return (
        <>
            <NameButton 
                data={{ name: data.name, lastName: data.lastName, publicName: data.publicName }} 
                originalArray={originalArray}
                setPrincipalLoading={setPrincipalLoading}
            />
            <BirthdateButton data={data.birthdate} />
            <GenderButton data={data.gender} />
        </>
    )
}
