import { createBrowserRouter } from "react-router-dom";
import Home from "../page";
import Layout from "../page/Layout";
import PersonalInfo from "../page/personal-info";
import DataAndPrivacy from "../page/data-and-privacy";

export const YugaRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "personal-info",
                element: <PersonalInfo />
            },
            {
                path: "data-and-privacy",
                element: <DataAndPrivacy />
            },
            {
                path: "security",
                element: <div>Seguridad</div>
            }
        ]
    }
])