import React from 'react'
import PfpButton from './pfp/Button'
import PfbButton from './pfb/Button'

export default function YourImage({ originalArray, data, setPrincipalLoading }) {
  return (
    <>
      <PfpButton value={data.photoURL} setPrincipalLoading={setPrincipalLoading} />
      <PfbButton value={data.bannerURL} setPrincipalLoading={setPrincipalLoading} />
    </>
  )
}
