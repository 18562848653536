import React, { useState } from 'react'
import SectionButton from '../../../../components/buttons/SectionButton'
import Modal from '../../../../components/modal/Modal'

export default function BirthdateButton({ data }) {
  const [ open, setOpen ] = useState(false);

  const [ loading, setLoading ] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }
  return (
    <>
      <SectionButton 
        title="Fecha de nacimiento"
        value={data ? new Intl.DateTimeFormat('es', { dateStyle: 'long', timeZone: 'GMT' }).format(data) : 'Sin fecha de nacimiento'}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        title={"Cambiar fecha de nacimiento"}
        loading={loading}
        close={handleClose}
      >
        hola
      </Modal>
    </>
  )
}
