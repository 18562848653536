import React, { useState } from 'react'
import SectionButton from '../../../../components/buttons/SectionButton'
import Modal from '../../../../components/modal/Modal';

export default function GenderButton({ data }) {
    const [ open, setOpen ] = useState(false);

    const [ loading, setLoading ] = useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    }
  
    const handleClose = () => {
      setOpen(false);
    }

    const handleGender = () => {
        if (data.toString() === '1') return 'Masculino';
        if (data.toString() === '2') return 'Femenino';
        if (data.toString() === '3') return 'No especificado';
    }
    
    return (
        <>
            <SectionButton 
                title="Género"
                value={data ? handleGender() : 'Sin género'}
                onClick={handleOpen}
            />
            <Modal
              open={open}
              title={"Cambiar género"}
              loading={loading}
              close={handleClose}
            >
              ola 🍑
            </Modal>
        </>
    )
}
